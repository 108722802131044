<div class="container page home">
    <div *ngIf="!ready" class="spinner-wrap">
        <app-spinner></app-spinner>
    </div>

    <ng-container *ngIf="ready">
        <div class="row">
            <div class="col-md-6" [class.col-md-12]="!user"><h1>Meatindex {{indexData.year}} <span class="light" style="white-space: nowrap;">{{'home_week' | translate}} {{indexData.weekNr}}</span></h1><div class="subtitle">{{'monitor_based_on_numbers_of' | translate}} {{weekInfo.previous.weekNr}}</div></div>
            <div class="col-md-6" *ngIf="user && !AuthenticationService.isFederation() && user.company.type !== 'gov'">
                <div class="d-flex flex-column align-items-end align-items-start--sm button-wrap">
                    <ng-container *ngIf="user">
                        <button *ngIf="!editIndexBtn" class="button green text-center justify-content-center" [class.disabled]="preventNewIndexUntil" [routerLink]="[this.HelpersService.getUrl('new')]"><i class="im-icon im-icon-plus"></i><span>{{'home_new_index_btn_1' | translate}} {{weekInfo.input.weekNr}} {{'home_new_index_btn_2' | translate}}</span></button>
                        <button *ngIf="editIndexBtn" class="button green text-center justify-content-center" [routerLink]="[this.HelpersService.getUrl('new')]"><i class="im-icon im-icon-pencil"></i><span>{{'home_new_index_btn_1' | translate}} {{weekInfo.input.weekNr}} {{'home_new_index_btn_2_edit' | translate}}</span></button>
                        <span *ngIf="preventNewIndexUntil" class="prevent-new-index-descr">{{'home_prevent_new_index_descr_1' | translate}} {{weekInfo.input.weekNr}} {{'home_prevent_new_index_descr_2' | translate}} {{preventNewIndexUntil | formatTs: 'dddd DD MMMM YYYY'}}</span>
                    </ng-container>
                </div>
            </div>
            @if (user.company.type === 'gov') {
                <div class="col-md-6">
                    <div class="prev-next-wrap" [class.no-pointer]="loading">
                        <button (click)="prev()"><i class="im-icon im-icon-chev-left"></i></button>
                        <button (click)="next()" [disabled]="maxDate === activeDate()"><i class="im-icon im-icon-chev-right"></i></button>
                    </div>
                </div>
            }
        </div>
        <div class="select-table d-flex" *ngIf="!AuthenticationService.isFederation()">
            <ng-container *ngFor="let code of speciesPartCodes">
                <a [routerLink]="['../' + code]" [class.active]="selectedTable == code" (click)="selectTable(code)">{{'home_' + code | translate}}</a>
            </ng-container>
        </div>
        <div class="select-table d-flex" *ngIf="AuthenticationService.isFederation()">
            <ng-container *ngFor="let indexType of federationIndexTypes">
                <button [class.active]="selectedFederationIndexType.code == indexType.code" (click)="selectedFederationIndexType = indexType">{{indexType.label}}</button>
            </ng-container>
        </div>
        <ng-container *ngFor="let species of indexData.data; index as $i; trackBy: trackByFn">
            <!-- table -->
            <table class="index hide--xs" *ngIf="species.code == selectedTable" [class.saving]="loading">
                <tr>
                    <th class="head--part">{{'home_part' | translate}}</th>
                    <th class="head--cat" *ngIf="true || !IndexService.noCategories(species.parts)">{{'home_category' | translate}}</th>
                    <th class="head--ticker">{{'home_meatindex_ticker' | translate}}</th>
                    <th class="head--traded">{{'home_traded_in_belgium' | translate}}</th>
                    <th class="head--export" style="width: .1%; min-width: 140px;">{{'th_export' | translate}}</th>
                </tr>
                <ng-container *ngFor="let part of species.parts; index as $j">
                    <tr class="tr--spacer" *ngIf="part.spacer">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="tr--part" *ngIf="!part.spacer" [class.tr--first]="IndexService.isFirstOfCategory(part.name, species.parts, $j)" [class.tr--last]="IndexService.isLastOfCategory(part.name, species.parts, $j)">
                        <!-- part -->
                        <td style="vertical-align: middle;" *ngIf="IndexService.isFirstOfCategory(part.name, species.parts, $j)" class="part" [attr.rowspan]="IndexService.isFirstOfCategory(part.name, species.parts, $j) ? IndexService.amountOfRows(part.name, species.parts) : 1">
                            <div class="d-flex align-items-center">
                                {{part.name}}
                            </div>
                        </td>
                        <!-- category -->
                        <td style="vertical-align: middle;" class="cat" *ngIf="true || !IndexService.noCategories(species.parts)">
                            {{part.cat}}
                        </td>
                        <!-- ticker index prev week -->
                        <td style="vertical-align: middle;">
                            <div *ngIf="HelpersService.isDefined(part[getIndexKey()])" class="ticker d-inline-flex align-items-center" [ngClass]="IndexService.getDirectionClass(part[getIndexKey()])">
                                <i class="im-icon im-icon-{{IndexService.getDirectionIcon(part[getIndexKey()])}}"></i> <span style="margin-right: 1px;">&euro;</span>{{part[getIndexKey()] | cent}}{{part.underThreshold && '*'}}
                            </div>
                            <div *ngIf="!HelpersService.isDefined(part[getIndexKey()])">-</div>
                        </td>
                        <!-- amount traded -->
                        <td style="vertical-align: middle;">
                            <div *ngIf="HelpersService.isDefined(part[getIndexKey()])" class="d-flex align-items-center">
                                <div class="amount">{{part[getAmountKey()] | formatNumber}}</div>
                                <span *ngIf="part.unit" class="pieces">{{'home_unit_' + part.unit | translate}}</span>
                            </div>
                            <div *ngIf="!HelpersService.isDefined(part[getIndexKey()])">-</div>
                        </td>
                        <!-- export -->
                        <td style="vertical-align: middle;">
                            <div *ngIf="part.exportable" class="d-flex align-items-center">
                                <button (click)="exportPart(part)" [class.disabled]="exportingPart?.id === part?.id" [class.no-pointer]="exportingPart" class="button-link fontsize-m export-btn">
                                    <i class="im-icon im-icon-download"></i>
                                    <span>{{'misc_export' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="!part.exportable">-</div>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div class="show--xs index mobile-index" *ngIf="species.code == selectedTable">
                <ng-container *ngFor="let part of species.parts; index as $j">
                    <div class="mobile-index-section d-flex flex-column" *ngIf="!part.spacer">
                        <!-- name, cat -->
                        <div class="d-flex align-items-end">
                            <span class="mobile-index-part">{{part.name}}</span>
                            <span class="mobile-index-cat">{{part.cat}}</span>
                        </div>
                        <div class="d-flex">
                            <!-- ticker -->
                            <div class="d-flex flex-column">
                                <span class="mobile-index-label">{{'homemobile_label_meatindex' | translate}}</span>
                                <span class="mobile-index-value">
                                    <div *ngIf="HelpersService.isDefined(part[getIndexKey()])" class="ticker d-inline-flex align-items-center" [ngClass]="IndexService.getDirectionClass(part[getIndexKey()])">
                                        <i class="im-icon im-icon-{{IndexService.getDirectionIcon(part[getIndexKey()])}}"></i> <span style="margin-right: 1px;">&euro;</span>{{part[getIndexKey()] | cent}}{{part.underThreshold && '*'}}
                                    </div>
                                    <span *ngIf="!HelpersService.isDefined(part[getIndexKey()])" class="mobile-index-value">-</span>
                                </span>
                            </div>
                            <!-- amount -->
                            <div class="d-flex flex-column">
                                <span class="mobile-index-label">{{'homemobile_label_traded_in_belgium' | translate}}</span>
                                <span *ngIf="HelpersService.isDefined(part[getIndexKey()])" class="mobile-index-value amount">{{part[getAmountKey()] | formatNumber}}<span *ngIf="part.unit" class="pieces">{{'home_unit_' + part.unit | translate}}</span></span>
                                <span *ngIf="!HelpersService.isDefined(part[getIndexKey()])" class="mobile-index-value">-</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="species.code == selectedTable && memo_activeIndexHasPartsUnderTreshold(indexData?.data, selectedTable)" class="under-threshold-disclaimer">*{{'index_mail_under_threshold' |  translate}}</div>

            <!-- <ng-container *ngIf="AuthenticationService.isFederation()">
                <div class="d-flex align-items-center justify-content-end">
                    <div class="federation-participants" *ngIf="federationGetParticipants() !== false">{{'home_amount_participants' | translate}} {{federationGetParticipants()}}</div>
                </div>
            </ng-container> -->
            
        </ng-container>


    </ng-container>
</div>